import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Form } from 'react-final-form';
import InputField from '../../../components/InputField';
import DialogFooter from '../../../components/DialogFooter';
import { addClass, removeClass } from '../../DatosCliente/helpers/Dialog';
import { dialogClient, walletPage } from '../../../configs/textos';
import validateEmptyFields from '../../../helpers/ValidateFields';
import DateField from '../../../components/DateField';
import translate from '../../../helpers/Translate';

const WalletDialog = (props) => {
	const { t } = useTranslation();
	const { open, setOpenDialog, loading, onSubmitUser, i18n, wallet } = props;

	const [displayBasic, setDisplayBasic] = useState(open);
	const [dateInit, setDateInit] = useState('');
	const [dateEnd, setDateEnd] = useState('');
	const [errorDates, setErrorDates] = useState('');

	const restoreDialogAsDefault = () => {
		setDisplayBasic(false);
		setOpenDialog(false);
		setDateInit('');
		setDateEnd('');
		setErrorDates('');
	};

	const onSubmit = (data) => {
		if (errorDates) return; // Prevent submission if date validation fails

		const formattedData = {
			...data,
			init_date: dateInit,
			end_date: dateEnd,
			minimum_units: parseInt(data.minimum_units, 10),
			maximum_units: parseInt(data.maximum_units, 10),
		};
		console.log('formattedData', formattedData);

		onSubmitUser(formattedData);
	};

	const validate = (data) =>
		validateEmptyFields(
			['name', 'description', 'minimum_units', 'maximum_units'],
			data
		) || (errorDates ? 'Invalid Dates' : undefined);

	useEffect(() => {
		const initialDateInit = new Date();
		setDateInit(initialDateInit);
		if (wallet?.init_date) {
			setDateInit(new Date(wallet.init_date));
		}
		if (wallet?.end_date) {
			setDateEnd(new Date(wallet.end_date));
		}

		if (open) {
			addClass(document.body, 'body-overflow-hidden');
		} else {
			removeClass(document.body, 'body-overflow-hidden');
		}
		setDisplayBasic(open);
	}, [open]);
	let description = null;
	if (wallet?.description) {
		const parsedDescription = JSON.parse(wallet.description)[translate(i18n)];
		description =
			wallet.description.length > 65
				? `${parsedDescription?.substring(0, 65)}...`
				: parsedDescription;
	}
	const handleInitDateChange = useCallback((initDate) => {
		setDateInit(initDate || new Date());
	}, []);

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={
				wallet && Object.keys(wallet).length > 0 // Check if wallet is not empty
					? {
							wallet_def_id: wallet.wallet_def_id,
							name: wallet.name
								? JSON.parse(wallet.name)[translate(i18n)]
								: null,

							description,

							requisites: wallet.requisites
								? JSON.parse(wallet.requisites)[translate(i18n)]
								: null,

							terms_and_conditions: wallet.terms_and_conditions
								? JSON.parse(wallet.terms_and_conditions)[translate(i18n)]
								: null,

							min_balance_units:
								(wallet?.min_balance_units ??
									parseInt(wallet.min_balance_units, 10)) ||
								0,
							maximum_units:
								(wallet?.maximum_units ?? parseInt(wallet.maximum_units, 10)) ||
								100,
							val_period_count:
								(wallet?.val_period_count ??
									parseInt(wallet.val_period_count, 10)) ||
								365,
							period_type_id:
								(wallet?.period_type_id ??
									parseInt(wallet.period_type_id, 10)) ||
								2,
							init_method_id:
								(wallet?.init_method_id ??
									parseInt(wallet.init_method_id, 10)) ||
								1,
							payment_method_id:
								(wallet?.payment_method_id ??
									parseInt(wallet.payment_method_id, 10)) ||
								14,
							unit_type_id:
								(wallet?.unit_type_id ?? parseInt(wallet.unit_type_id, 10)) ||
								3,
							pocket_def_id:
								(wallet?.pocket_def_id ?? parseInt(wallet.pocket_def_id, 10)) ||
								1,
							wallet_def_order:
								(wallet?.wallet_def_order ??
									parseInt(wallet.wallet_def_order, 10)) ||
								11,
							max_units:
								(wallet?.max_units ?? parseInt(wallet.max_units, 10)) || 2,
							init_date: wallet.init_date,
							end_date: wallet.end_date,
							pack_id: (wallet?.pack_id ?? parseInt(wallet.pack_id, 10)) || 11,
							is_private: (wallet?.is_private ?? wallet.is_private) || false,
							serial_number:
								(wallet?.serial_number ?? parseInt(wallet.serial_number, 10)) ||
								14,
							text_color:
								(wallet?.text_color ?? wallet.text_color) || '#FFFFFF',
							transfer_number:
								(wallet?.transfer_number ??
									parseInt(wallet.transfer_number, 10)) ||
								3,
							icon_color:
								(wallet?.icon_color ?? wallet.icon_color) || '#FFFFFF',
							max_simultaneous_transfers:
								(wallet?.max_simultaneous_transfers ??
									parseInt(wallet.max_simultaneous_transfers, 10)) ||
								11,

							deposit_description: wallet.deposit_description
								? JSON.parse(wallet.deposit_description)
								: null,
					  }
					: {} // Default to an empty object if wallet is empty
			}
			render={({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit} className='p-d-flex p-grid p-col-12'>
					<Dialog
						header={!wallet ? t(walletPage.create) : t(walletPage.edit)}
						visible={displayBasic}
						style={{ width: '500px' }}
						onHide={() => {
							restoreDialogAsDefault();
							form.reset();
						}}
						footer={
							<DialogFooter
								label={t(dialogClient.save)}
								onClickHandler={() => {
									handleSubmit();
									form.reset();
								}}
								loading={loading}
							/>
						}
					>
						<div className='card'>
							<div className='p-ml-4 p-mr-5'>
								<div className='font16 p-margin'>
									<div className='p-grid p-jc-center p-flex-column'>
										<InputField
											id='name'
											label={t(walletPage.name)}
											placeholder={t(walletPage.name)}
										/>
										<InputField
											id='description'
											label={t(walletPage.description)}
											placeholder={t(walletPage.description)}
										/>
										<InputField
											id='requisites'
											label={t(walletPage.requisites)}
											placeholder={t(walletPage.requisites)}
										/>
										<InputField
											id='terms_and_conditions'
											label={t(walletPage.terms_and_conditions)}
											placeholder={t(walletPage.terms_and_conditions)}
										/>
										<InputField
											id='min_balance_units'
											label={t(walletPage.min_balance_units)}
											placeholder={t(walletPage.min_balance_units)}
											type='number'
										/>
										<InputField
											id='maximum_units'
											label={t(walletPage.maximum_units)}
											placeholder={t(walletPage.maximum_units)}
											type='number'
										/>
										<InputField
											id='val_period_count'
											label={t(walletPage.val_period_count)}
											placeholder={t(walletPage.val_period_count)}
											type='number'
										/>

										<InputField
											id='text_color'
											label={t(walletPage.text_color)}
											placeholder={t(walletPage.text_color)}
										/>
										<InputField
											id='icon_color'
											label={t(walletPage.icon_color)}
											placeholder={t(walletPage.icon_color)}
										/>
										<InputField
											id='deposit_description'
											label={t(walletPage.deposit_description)}
											placeholder={t(walletPage.deposit_description)}
										/>
										<InputField
											id='transfer_number'
											label={t(walletPage.transfer_number)}
											placeholder={t(walletPage.transfer_number)}
											type='number'
										/>
										<InputField
											id='max_simultaneous_transfers'
											label={t(walletPage.max_simultaneous_transfers)}
											placeholder={t(walletPage.max_simultaneous_transfers)}
											type='number'
										/>
									</div>
								</div>
								<DateField
									t={t}
									onChangeInit={handleInitDateChange}
									onChangeEnd={(endDate) => setDateEnd(endDate)}
									dateInit={dateInit}
									dateEnd={dateEnd}
									setErrorDates={setErrorDates}
									errorDates={errorDates}
									disabled={loading}
									verticalStyle
								/>
							</div>
						</div>
					</Dialog>
				</form>
			)}
		/>
	);
};

export default withTranslation('translations')(WalletDialog);

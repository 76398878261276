import { Card } from 'primereact/card';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../store/actions/projectAction';
import { clienteError, clienteTablaError } from '../../configs/mensajes';
import { search } from '../../configs/textos';
import '../../layout/customs/responsiveDatatable.scss';
import Searchbar from '../../components/Searchbar';
import useGetClients from './hooks/useGetClients';
import ClientsTable from './ClientsTable';

const Clientes = (props) => {
	const {
		global: { networkId },
	} = props;

	const mockData = {
		listCustomersInNetwork: {
			total: 23,
			items: [
				{
					email: 'adela_saavedra@yopmail.com',
					name: 'Adela',
					surname: 'Saavedra',
					customer_id: 207,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'ponceadolfo00@gmail.com',
					name: 'Adolfo',
					surname: 'Ponce',
					customer_id: 235,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'arturomak99@gmail.com',
					name: 'Adolfo',
					surname: 'Ponce',
					customer_id: 236,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'alfonso0@yopmail.com',
					name: 'Alfonso',
					surname: 'Romero',
					customer_id: 205,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'amaia1@yopmail.com',
					name: 'Amaia',
					surname: 'Aah',
					customer_id: 204,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'amaia2@yopmail.com',
					name: 'Chris',
					surname: 'Aguayo',
					customer_id: 78,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'brivemx@gmail.com',
					name: 'Christian',
					surname: 'Aguayo',
					customer_id: 227,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'elsa.gonzalez@yopmail.com',
					name: 'Elsa',
					surname: 'Gonzalez',
					customer_id: 211,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'eriksaritho@gmail.com',
					name: 'Erik',
					surname: 'Pince',
					customer_id: 234,
					in_blacklist: false,
					document_id: null,
				},
				{
					email: 'estela.vanegas@yopmail.com',
					name: 'Estela',
					surname: 'Vanegas',
					customer_id: 208,
					in_blacklist: false,
					document_id: null,
				},
			],
		},
	};
	const useMockData = false;

	const [searchInput, setSearchInput] = useState('');
	const [offset, setOffset] = useState(0);
	const [totalClientes, setTotalClientes] = useState(0);
	const { t } = useTranslation();
	const toast = useRef(null);

	const { data, loading, error } = useMockData
		? { data: mockData, loading: false }
		: useGetClients({
				offset,
				searchInput,
				networkId,
		  });

	console.log('data', data);

	if (error) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: clienteError,
			life: 3000,
		});
	}

	const { items: clientes = [], total } =
		data?.listCust_named_customers_spu || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalClientes(total);
		}
	}, [total]);

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-page-title'>{t('listaClientes')}</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable'>
					<Searchbar
						value={searchInput}
						onChange={(e) => {
							setSearchInput(e.target.value);
							setOffset(0);
						}}
						placeholder={t(search.placeholder)}
					/>
					<ClientsTable
						value={clientes}
						loading={data?.listCustomersInNetwork == null && loading}
						emptyMessage={t(clienteTablaError)}
						rows={10}
						total={totalClientes}
						onPage={onPage}
						first={offset}
						lazy
					/>
				</Card>
			</div>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Clientes));

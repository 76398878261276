import React, { useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { useMutation } from '@apollo/client';
import { clientAuthCognito as client } from '../../client';
import Title from '../../components/Title';
import { walletDefPage, dialog } from '../../configs/textos';
import Conditions from './components/Conditions';
import Category from './components/Category';
import WalletData from './components/WalletData';
import Validity from './components/Validity';
import WalletDialog from '../Usuarios/dialogs/WalletDialog';
import { updateWalletSPI } from '../../graphql/mutations';
import { getWalletDef } from '../../graphql/queries';
import ActionBodyTemplate from '../../components/ActionBodyTemplate';

function WalletDefCard(props) {
	const { walletDef } = props;
	const { t } = useTranslation();
	const toast = useRef(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [wallet, setWallet] = useState({});

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	const [updateWalletMutation, { loading: loadingUpdatingWallet }] =
		useMutation(updateWalletSPI, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.updateWalletSuccess));
				setModalVisible(false);
			},
			onError(err) {
				showErrorToast(err.message);
				setModalVisible(false);
			},
		});

	function onSubmitUser(data) {
		// Format input data to match API expectations
		const input = {
			name: JSON.stringify({ en: data.name, es: data.name }),
			description: JSON.stringify({
				en: data.description,
				es: data.description,
			}),
			requisites: JSON.stringify({ en: data.requisites, es: data.requisites }),
			terms_and_conditions: JSON.stringify({
				en: data.terms_and_conditions,
				es: data.terms_and_conditions,
			}),
			min_balance_units: parseInt(data.min_balance_units, 10),
			maximum_units: parseInt(data.maximum_units, 10),
			val_period_count: parseInt(data.val_period_count, 10) || 365,
			period_type_id: parseInt(data.period_type_id, 10) || 2,
			init_method_id: parseInt(data.init_method_id, 10) || 1,
			payment_method_id: parseInt(data.payment_method_id, 10) || 14,
			unit_type_id: parseInt(data.unit_type_id, 10) || 3,
			pocket_def_id: parseInt(data.pocket_def_id, 10) || 1,
			wallet_def_id: parseInt(data.wallet_def_id, 10),
			wallet_def_order: parseInt(data.wallet_def_order, 10) || 11,
			max_units: parseInt(data.max_units, 10) || 2,
			init_date: data.init_date || '2021-01-01T00:00:00.000Z',
			end_date: data.end_date || '9999-12-31T23:59:59.000Z',
			pack_id: parseInt(data.pack_id, 10) || 11,
			is_private: data.is_private || false,
			serial_number: parseInt(data.serial_number, 10) || 14,
			text_color: data.text_color,
			transfer_number: parseInt(data.transfer_number, 10) || 0,
			icon_color: data.icon_color,
			max_simultaneous_transfers: parseInt(data.max_simultaneous_transfers, 10),
			deposit_description: JSON.stringify({
				en: data.deposit_description,
				es: data.deposit_description,
			}),
		};

		// Call API or mutation with formatted input
		console.log('Formatted Input:', input);
		if (wallet && Object.keys(wallet).length > 0) {
			input.wallet_def_id = wallet?.wallet_def_id;
			updateWalletMutation({
				variables: { input },
				refetchQueries: [
					{
						query: getWalletDef,
						variables: {
							walletDefId: wallet.wallet_def_id,
							input: {
								filter: { disabled_at: { isNull: true } },
								pagination: { limit: 10 },
								orderBy: { field: 'name', direction: 'ASC' },
							},
						},
					},
				],
			});
		}
	}

	return (
		<>
			<div className='p-page-title'>{t(walletDefPage.title)}</div>
			<Card>
				<div className='p-d-flex p-ai-center p-jc-between'>
					<div>
						<Title label={t(walletDefPage.data.toUpperCase())} />
					</div>
					<ActionBodyTemplate
						onEditClick={() => {
							setModalVisible(true);
							setWallet(walletDef);
						}}
					/>
				</div>
				{/* <Title label={t(walletDefPage.data.toUpperCase())} /> */}
				<WalletData walletDef={wallet} {...props} />
				<div className='p-d-flex p-mt-5'>
					<div className='third'>
						<Title label={t(walletDefPage.validity.toUpperCase())} />
						<Validity walletDef={walletDef} />
					</div>
					<div className='third'>
						<Title label={t(walletDefPage.category.toUpperCase())} />
						<Category walletDef={walletDef} />
					</div>
					<div className='third'>
						<Title label={t(walletDefPage.conditions.toUpperCase())} />
						<Conditions walletDef={walletDef} />
					</div>
				</div>
				<WalletDialog
					open={modalVisible}
					setOpenDialog={setModalVisible}
					onSubmitUser={onSubmitUser}
					loading={loadingUpdatingWallet}
					wallet={wallet}
				/>
			</Card>
		</>
	);
}

export default withTranslation('translations')(WalletDefCard);

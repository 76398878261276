export const EDIT_CUSTOMER = 1;
export const BLOCK_CUSTOMER = 2;
export const EDIT_WALLET = 3;
export const BLOCK_WALLET = 4;
export const CREATE_CERTIFICATE = 5;
export const EDIT_BALANCE = 6;
export const EDIT_CERTIFICATE = 7;
export const DELETE_CERTIFICATE = 8;
export const CREATE_BUS = 9;
export const EDIT_BUS = 10;
export const DELETE_BUS = 11;
export const CREATE_VALIDATOR = 12;
export const EDIT_VALIDATOR = 13;
export const DELETE_VALIDATOR = 14;
export const CREATE_USER = 15;
export const EDIT_USER = 16;
export const EDIT_POCKET = 18;
export const DELETE_USER = 17;

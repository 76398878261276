import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../store/actions/projectAction';
import MovementsTable from './MovementsTable';
import useGetCustomerMovements from './hooks/useGetMovements';

const Movements = ({ global }) => {
	const mockData = {
		listNetworkMovements: {
			items: [
				{
					amount: 0.65,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000351',
					date_time: '2022-12-31T16:09:10.737Z',
					available_balance: 4.35,
					linea: '??',
					bus: '2003',
					description: null,
					transaction_id: null,
					unit_type_short_name: '€',
					validation_id: 2040,
					wallet_transaction_id: 3164,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Consumption","es":"Consumo"}',
					customer_id: 210,
					check_digit: 8,
					parada_origen: 'Desconocida',
					parada_destino: null,
					external_id: 9999,
					parada_id: 9999,
					fleet_code: '28',
					sentido: '0',
					conductor: '9999',
					sodigosa: '99999999',
					validation_method: null,
				},
				{
					amount: 21.5,
					name: '{"en":"Abono 30 EN","es":"Abono 30"}',
					wallet_serial_number: '975210000000357',
					date_time: '2022-12-29T15:13:25.484Z',
					available_balance: 0.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VT0FN9swqzZw',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3166,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 204,
					check_digit: 3,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 21.5,
					name: '{"en":"Abono 30 EN","es":"Abono 30"}',
					wallet_serial_number: '975210000000356',
					date_time: '2022-12-29T14:09:19.411Z',
					available_balance: 0.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTQz6lIfo2IO',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3165,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 204,
					check_digit: 5,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 21.5,
					name: '{"en":"Abono 30 EN","es":"Abono 30"}',
					wallet_serial_number: '975210000000355',
					date_time: '2022-10-25T16:33:58.621Z',
					available_balance: 0.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTwGNawdxJvC',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3161,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 215,
					check_digit: 7,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000354',
					date_time: '2022-10-21T08:29:19.256Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTB27n08eUrB',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3158,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 78,
					check_digit: 2,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000353',
					date_time: '2022-10-04T09:16:52.335Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VT7VyDiw4K5F',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3154,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 212,
					check_digit: 4,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000352',
					date_time: '2022-10-04T09:15:19.002Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTQIYKNFJ4PW',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3153,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 211,
					check_digit: 6,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000351',
					date_time: '2022-10-04T09:13:27.040Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VToxxwd3qfiz',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3152,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 210,
					check_digit: 8,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000350',
					date_time: '2022-10-04T09:11:39.547Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTd3nLvrU7ND',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3151,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 209,
					check_digit: 0,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000349',
					date_time: '2022-10-04T09:09:49.946Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTeeehaZKTZa',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3150,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 208,
					check_digit: 2,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000348',
					date_time: '2022-10-04T09:08:06.953Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTWsiHZGtpHM',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3149,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 207,
					check_digit: 4,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000347',
					date_time: '2022-10-04T09:06:13.690Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTYZXphgPl6u',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3148,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 206,
					check_digit: 6,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000346',
					date_time: '2022-10-04T09:04:13.069Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTrG0pA71NPW',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3147,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 205,
					check_digit: 8,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000345',
					date_time: '2022-10-04T08:57:55.492Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTC1S0Y820H8',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3146,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 204,
					check_digit: 0,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000344',
					date_time: '2022-10-04T08:56:40.569Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTIN0qQFzeNS',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3145,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
					customer_id: 204,
					check_digit: 3,
					parada_origen: null,
					parada_destino: null,
					external_id: null,
					parada_id: null,
					fleet_code: null,
					sentido: null,
					conductor: null,
					sodigosa: null,
					validation_method: null,
				},
			],
			total: 36,
		},
	};
	const useMockData = false;
	const { networkId } = global;
	const [inputQuery, setInputQuery] = useState({});
	const [refetchQuery, setRefetchQuery] = useState(false);
	const [offset, setOffset] = useState(0);
	const { t } = useTranslation();
	const [totalMovements, setTotalMovements] = useState(0);
	const toast = useRef(null);

	const { data, loading, error } = useMockData
		? { data: mockData, loading: false }
		: useGetCustomerMovements({
				offset,
				inputQuery,
				networkId,
				refetchQuery,
				setRefetchQuery,
		  });
	const { items: movements = [], total } = data?.listNetworkMovements || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalMovements(total);
		}
	}, [total]);

	if (error) {
		showErrorToast(error?.message);
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onFilter(input) {
		if (JSON.stringify(input) === JSON.stringify(inputQuery) && !refetchQuery) {
			setRefetchQuery(true);
		}
		setOffset(0);
		setInputQuery(input);
	}

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>{t('Movimientos')}</div>
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable p-mt-3'>
					<MovementsTable
						movements={movements}
						title={t('Movimientos')}
						onFilter={onFilter}
						rows={10}
						total={totalMovements}
						totalRecords={totalMovements}
						onPage={onPage}
						first={offset}
						loading={loading}
						lazy
					/>
				</Card>
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Movements));

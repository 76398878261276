import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useTranslation, withTranslation } from 'react-i18next';
import * as projectAction from '../../store/actions/projectAction';
import SaldoVencidoTable from './SaldoVencidoTable';
import useGetCustomerMovements from './hooks/useGetMovements';
import LoadingSpinner from '../../components/LoadingSpinner';

const Movements = ({ global }) => {
	const { networkId, movementsExpired } = global;
	const [inputQuery, setInputQuery] = useState({});
	const [refetchQuery, setRefetchQuery] = useState(false);
	const [offset, setOffset] = useState(0);
	const { t } = useTranslation();
	const [totalMovements, setTotalMovements] = useState(0);
	const [selectedMovements, setSelectedMovements] = useState([]);

	const toast = useRef(null);
	const { data, loading, error } = useGetCustomerMovements({
		offset,
		inputQuery,
		networkId,
		refetchQuery,
		setRefetchQuery,
	});
	// Fetch selected movements from localStorage
	useEffect(() => {
		const fetchSelectedMovements = () => {
			const res = movementsExpired;

			if (res) {
				try {
					const movements = res?.ListWalletBalanceExpiredAndRemoved || {
						items: [],
						totalWallets: 0,
						totalWalletsAmount: 0,
					};

					// Ensure the parsed data is valid before updating state
					setSelectedMovements(movements);
				} catch (err) {
					console.error('Error parsing localStorage data:', err);
				}
			} else {
				console.warn('localStorage is empty or not set.');
			}
		};
		fetchSelectedMovements();
	}, [loading, movementsExpired]);

	const { totalWallets: total = 0 } =
		data?.ListWalletBalanceExpiredAndRemoved || {};

	useEffect(() => {
		if (total >= 0) {
			setTotalMovements(total);
		}
	}, [total, selectedMovements]);

	if (error) {
		showErrorToast(error?.message);
	}

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	function onFilter(input) {
		if (JSON.stringify(input) === JSON.stringify(inputQuery) && !refetchQuery) {
			setRefetchQuery(true);
		}
		setOffset(0);
		setInputQuery(input);
	}

	const onPage = ({ first }) => !loading && setOffset(first);

	return (
		<div className=' p-grid growable p-container'>
			<div className='p-d-flex p-jc-between p-mb-3'>
				<div className='p-page-title p-p-0 p-as-end'>
					{t('Reporte Diario Saldos Vencidos')}
				</div>
			</div>
			<div className='p-table-container'>
				<Toast ref={toast} />
				<Card className='growable p-mt-3'>
					{!selectedMovements ? (
						<LoadingSpinner />
					) : (
						<SaldoVencidoTable
							movements={selectedMovements.items}
							title={t('Movimientos')}
							onFilter={onFilter}
							rows={10}
							total={selectedMovements.totalWallets}
							totalRecords={totalMovements}
							onPage={onPage}
							first={offset}
							loading={loading}
							lazy
						/>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							gap: '1rem',
							padding: '1rem',
							fontWeight: 'bold', // Apply bold to all child elements
						}}
					>
						<span
							style={{
								fontWeight: 'bold',
								fontSize: 'x-large',
							}}
						>
							Total
						</span>
						<span
							style={{
								fontWeight: 'bold',
								fontSize: 'x-large',
							}}
						>
							Eventos Totales {selectedMovements.totalWallets}
						</span>
						<span
							style={{
								fontWeight: 'bold',
								fontSize: 'x-large',
							}}
						>
							Monto Total ${selectedMovements.totalWalletsAmount}
						</span>
					</div>
				</Card>
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({
	global: rootReducer,
});
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(Movements));

import React, { useState } from 'react';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { Card } from 'primereact/card';
import * as projectAction from '../../store/actions/projectAction';
import LoginForm from './forms/LoginForm';
import NewPasswordForm from './forms/NewPasswordForm';
import ForgotPasswordCode from './forms/ForgotPasswordCode';

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: process.env.REACT_APP_REGION,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
	},
	Storage: {
		AWSS3: {
			bucket: process.env.REACT_APP_S3_BUCKET,
			region: process.env.REACT_APP_REGION,
		},
		customPrefix: {
			public: '',
		},
	},
});

const Login = ({ children, setUserInfo, rootReducer, setNetworkId }) => {
	const [loginState, setLoginState] = useState('LOGIN');
	const [sysUser, setSysUser] = useState(null);
	const [loadingAuth, setLoadingAuth] = useState(false);
	const [errorAuth, setErrorAuth] = useState(null);
	const [sendingCode, setSendingCode] = useState(false);
	const [sendingCodeError, setSendingCodeError] = useState();
	const [forgotPassword, setForgotPassword] = useState(false);
	const [successChangePassword, setSuccessChangePassword] = useState(false);
	const [email, setEmail] = useState();

	function getSysUser() {
		return { user: 123 };
	}
	const loading = false;
	const error = false;

	async function onSubmit(formData) {
		setLoadingAuth(true);
		Auth.signIn(formData.email, formData.password)
			.then((user) => {
				setLoadingAuth(false);
				setSysUser(user);
				setErrorAuth(null);
				if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
					setLoginState('NEW_PASSWORD_REQUIRED');
				}
				if (user && user?.challengeName !== 'NEW_PASSWORD_REQUIRED') {
					getSysUser();
					setSuccessChangePassword(false);
				}
				setUserInfo(getSysUser());
				setNetworkId(1);
				setLoginState('LOGIN');
			})
			.catch((err) => {
				setErrorAuth(err.message);
				setLoadingAuth(false);
			});
	}

	async function onSubmitNewPasswordRequired(formData) {
		setLoadingAuth(true);
		Auth.completeNewPassword(sysUser, formData.newPassword, {})
			.then(() => {
				setLoadingAuth(false);
				getSysUser();
				setErrorAuth(null);
			})
			.catch((err) => {
				setErrorAuth(err.message);
				setLoadingAuth(false);
			});
	}

	async function onSubmitForgotPasswordCode(sysUserEmail) {
		setSendingCode(true);
		Auth.forgotPassword(sysUserEmail)
			.then(() => {
				setSendingCode(false);
				setLoginState('NEW_PASSWORD_REQUIRED');
				setForgotPassword(true);
				setEmail(sysUserEmail);
				setErrorAuth(null);
			})
			.catch((err) => {
				setSendingCode(false);
				setSendingCodeError(err.message);
			});
	}

	async function onSubmitForgotPassword(formData) {
		setLoadingAuth(true);
		Auth.forgotPasswordSubmit(email, formData.code, formData.newPassword)
			.then(() => {
				setSuccessChangePassword(true);
				setLoginState('LOGIN');
				setLoadingAuth(false);
				setErrorAuth(null);
			})
			.catch((err) => {
				setErrorAuth(err.message);
				setLoadingAuth(false);
			});
	}

	function loginPage() {
		return (
			<div className='loginPage'>
				<div className='p-grid p-justify-center'>
					<div className='p-col p-sm-12 p-lg-4 p-text-center p-mb-6'>
						<img width='150px' src='/images/logo_login.png' alt='Logo' />
					</div>
				</div>
				<div className='p-grid p-justify-center'>
					<Card>
						{loginState === 'LOGIN' && (
							<LoginForm
								onSubmitLogin={onSubmit}
								loading={loading || loadingAuth}
								error={error || errorAuth}
								openForgotPasswordCodeForm={() =>
									setLoginState('FORGOT_PASSWORD_CODE')
								}
								successChangePassword={successChangePassword}
							/>
						)}
						{loginState === 'NEW_PASSWORD_REQUIRED' && (
							<NewPasswordForm
								onSubmitNewPassword={
									forgotPassword
										? onSubmitForgotPassword
										: onSubmitNewPasswordRequired
								}
								loading={loading || loadingAuth}
								error={error || errorAuth}
								forgotPassword={forgotPassword}
							/>
						)}
						{loginState === 'FORGOT_PASSWORD_CODE' && (
							<ForgotPasswordCode
								onSubmitForgotCode={onSubmitForgotPasswordCode}
								loading={sendingCode}
								error={sendingCodeError}
							/>
						)}
					</Card>
				</div>
			</div>
		);
	}

	return rootReducer.user ? children : loginPage();
};

const mapStateToProps = (rootReducer) => ({ rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(Login);

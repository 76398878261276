export const LIST_CUSTOMERS = 1;
export const LIST_POCKETS = 2;
export const BUSES = 3;
export const VALIDATORS = 4;
export const LIST_USERS = 5;
export const LIST_MOVEMENTS = 6;
export const LIST_DOCUMENTS = 7;
export const LIST_MONEDERO = 8;
export const LIST_POCKET = 9;
export const LIST_SALDOVENCIDO = 10;

import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import WalletDetail from './WalletDetail';
import { walletDefPage } from '../../../configs/textos';

function Conditions({ walletDef }) {
	const { t } = useTranslation();
	return (
		<>
			<div className='p-d-flex p-ml-2 '>
				<WalletDetail
					label={t(walletDefPage.deposit)}
					info={walletDef?.deposit !== null ? `${walletDef.deposit} €` : '-'}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.initialRecharge)}
					info={
						walletDef?.initial_recharge !== null
							? `${walletDef.initial_recharge} €`
							: '-'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.minRecharge)}
					info={
						walletDef?.minimum_units !== null
							? `${walletDef.minimum_units} €`
							: '-'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.multiplicity)}
					info={
						walletDef?.multiple_units !== null
							? `${walletDef.multiple_units} €`
							: '-'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3'>
				<WalletDetail
					label={t(walletDefPage.maxBalance)}
					info={
						walletDef?.maximum_units !== null
							? `${walletDef.maximum_units} $MXN`
							: '-'
					}
				/>
			</div>
			<div className='p-d-flex p-ml-2 p-mt-3 p-mb-3'>
				<WalletDetail
					label={t(walletDefPage.minBalance)}
					info={
						walletDef?.min_balance_units !== null
							? `${walletDef.min_balance_units} $MXN`
							: '-'
					}
				/>
			</div>
		</>
	);
}
export default withTranslation('translations')(Conditions);

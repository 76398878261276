import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { setDropdownValue } from '../../../store/actions/commonAction';

function Filterbar() {
	const [dataDownloaded, setDataDownloaded] = useState(false);

	const csvExportRef = useRef(null);
	const toast = useRef(null);
	useEffect(() => {
		if (dataDownloaded) {
			csvExportRef?.current?.link.click();
			setDataDownloaded(false);
		}
	}, [dataDownloaded]);

	function onSubmit(data) {
		console.log('data', data);
	}

	const dispatch = useDispatch();
	const dropdownValue = useSelector((state) => state.dropdownValue); // Get from Redux
	const allExpiredMovements = useSelector((state) => state.movementsExpired); // Get from Redux

	const newData =
		allExpiredMovements?.ListWalletBalanceExpiredAndRemoved?.items || [];
	const total = `$${
		allExpiredMovements?.ListWalletBalanceExpiredAndRemoved
			?.totalWalletsAmount || '-'
	}`;
	const conta =
		allExpiredMovements?.ListWalletBalanceExpiredAndRemoved?.totalWallets ||
		'-';

	const exportPDF = () => {
		// eslint-disable-next-line new-cap
		const doc = new jsPDF();
		doc.text('Reporte Diario Saldos Vencidos', 14, 15);

		// Table headers
		const tableColumn = [
			'Fecha Ultimo Abono	',
			'Días de vencimiento',
			'Monedero',
			'Monto',
			'Fecha Cancelacion',
		];
		const tableRows = [];

		// Convert selectedMovements to table rows
		newData.forEach((movement) => {
			tableRows.push([
				new Date(movement.lastCashIn?.dateLastCashIn).toLocaleDateString(),
				Math.floor(
					(new Date(movement.Cust_wallet.exp_date).getTime() -
						new Date(movement.lastCashIn.dateLastCashIn).getTime()) /
						86400000
				),
				movement.Cust_wallet.wallet_id,
				movement.amount,
				new Date(movement.date_time).toLocaleDateString(),
			]);
		});
		// Add Total and Conta as a summary row
		tableRows.push(['', '', '', 'Monto Total:', total]);
		tableRows.push(['', '', '', 'Eventos Totales:', conta]);
		autoTable(doc, {
			head: [tableColumn],
			body: tableRows,
			startY: 20,
		});

		doc.save('movements_report.pdf');
	};

	const exportCSV = () => {
		const csvData = newData.map((txn) => ({
			'Fecha Ultimo Abono': new Date(
				txn.lastCashIn?.dateLastCashIn
			).toLocaleDateString(),
			'Días de vencimiento': Math.floor(
				(new Date(txn.Cust_wallet.exp_date).getTime() -
					new Date(txn.lastCashIn.dateLastCashIn).getTime()) /
					86400000
			),
			Monedero: txn.Cust_wallet.wallet_id,
			Monto: txn.amount,
			'Fecha Cancelacion': new Date(txn.date_time).toLocaleDateString(),
		}));

		// Ensure total and conta are displayed properly
		const summaryRow = {
			'Fecha Ultimo Abono': 'Monto Total',
			'Días de vencimiento': '',
			'Exp Date': '',
			Monedero: '',
			'Fecha Cancelacion': '',
			Monto: total,
		};

		const contaRow = {
			'Fecha Ultimo Abono': 'Eventos Totales',
			'Días de vencimiento': '',
			'Exp Date': '',
			Monedero: '',
			'Fecha Cancelacion': '',
			Monto: conta,
		};

		csvData.push(summaryRow);
		csvData.push(contaRow);
		const csv = Papa.unparse(csvData, { header: true });

		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
		saveAs(blob, 'transactions.csv');
	};

	return (
		<div>
			<Toast ref={toast} />
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit} className='p-col-12 p-p-0'>
						<div className='card movements-form'>
							<div className='p-mr-5'>
								<div className='font16 p-margin p-d-flex p-jc-between'>
									<div className='p-px-2 p-ml-2 p-d-flex p-ai-center'>
										<h3 className='text-base font-bold p-px-2'>Fecha</h3>
										<Calendar
											id='dropdownValue'
											value={dropdownValue ? new Date(dropdownValue) : null} // Prevents invalid date issue
											placeholder='dd/mm/aaaa'
											showIcon
											inputStyle={{ width: '114px', height: '42px' }}
											onChange={({ value }) =>
												dispatch(
													setDropdownValue(value ? new Date(value) : null)
												)
											}
											onClear={() => dispatch(setDropdownValue(null))}
											dateFormat='dd/mm/yy'
											className='calendar-input'
											minDate={
												new Date(new Date().setDate(new Date().getDate() - 45))
											}
										/>
									</div>
									<div className='p-d-flex p-ai-center'>
										<Button
											className='p-mr-2 p-button-secondary'
											onClick={exportCSV}
										>
											Download CSV
										</Button>

										<Button className='p-button-success' onClick={exportPDF}>
											Download PDF
										</Button>
									</div>
								</div>
							</div>
						</div>
					</form>
				)}
			/>
		</div>
	);
}
export default withTranslation('translations')(Filterbar);

export const SET_DROPDOWN_VALUE = 'SET_DROPDOWN_VALUE';

export const setDropdownValue = (value) => ({
	type: 'setDropdownValue',
	payload: value,
});

export const setMovementsExpired = (value) => ({
	type: 'setMovementsExpired',
	payload: value,
});

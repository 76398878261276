import React from 'react';
import { Field } from 'react-final-form';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale } from 'primereact/api';

export default function CalendarField({ label, id }) {
	addLocale('es', {
		firstDayOfWeek: 1,
		dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
		monthNames: [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre',
		],
		today: 'Hoy',
		clear: 'Limpiar',
	});

	locale('es');
	return (
		<Field
			name={id}
			render={({ input }) => (
				<div className='p-d-flex p-mb-3'>
					<label htmlFor={id} className='p-col-fixed p-text-bold label-dialog'>
						{label}
					</label>
					<div>
						<div>
							<Calendar
								{...input}
								placeholder='dd/mm/aaaa'
								inputStyle={{ width: '236px' }}
								dateFormat='dd/mm/yy'
								className='calendar-input'
								id={id}
								onClick={(e) => e.stopPropagation()} // Prevents focus loss
								onFocus={(e) => e.stopPropagation()} // Keeps calendar open
							/>
						</div>
					</div>
				</div>
			)}
		/>
	);
}

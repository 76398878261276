import { useQuery } from '@apollo/client';

import { clientAuthSaldos as client } from '../../../client';
import { getMonederos } from '../../../graphql/queries';

const useGetClients = ({ offset, searchInput }) => {
	const queryParams = {
		client,
		fetchPolicy: 'cache-and-network',
		variables: {
			input: {
				pagination: { limit: 1000, offset }, // Fetch more data if needed
				orderBy: { field: 'wallet_id', direction: 'DESC' },
			},
		},
	};

	const { data, loading, error } = useQuery(getMonederos, queryParams);

	// Get wallets list
	let wallets = data?.listCust_wallets?.items || [];

	if (searchInput) {
		const searchTerm = String(searchInput).trim();

		if (searchTerm.startsWith('*')) {
			// Match wallets ending with the search term (remove `*`)
			const searchValue = searchTerm.slice(1);
			wallets = wallets.filter((wallet) =>
				String(wallet.wallet_id).endsWith(searchValue)
			);
		} else if (searchTerm.endsWith('*')) {
			// Match wallets starting with the search term (remove `*`)
			const searchValue = searchTerm.slice(0, -1);
			wallets = wallets.filter((wallet) =>
				String(wallet.wallet_id).startsWith(searchValue)
			);
		} else {
			// Match exact wallet_id (no wildcards)
			wallets = wallets.filter(
				(wallet) => String(wallet.wallet_id) === searchTerm
			);
		}
	}

	return {
		data: { listCust_wallets: { items: wallets, total: wallets.length } },
		loading,
		error,
	};
};

export default useGetClients;

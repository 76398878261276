import React from 'react';
import { Button } from 'primereact/button';

function ActionBodyTemplate({ onEditClick, onDeleteClick }) {
	return (
		<div className='p-d-flex p-jc-center p-ai-center'>
			{onEditClick && (
				<div>
					<Button
						icon='pi pi-pencil'
						className='icon-button'
						onClick={onEditClick}
					/>
				</div>
			)}
			{onDeleteClick && (
				<div>
					<Button
						icon='pi pi-trash'
						className='icon-button'
						onClick={onDeleteClick}
					/>
				</div>
			)}
		</div>
	);
}

export default ActionBodyTemplate;

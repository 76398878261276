import React, { useState, useEffect } from 'react';

import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as projectAction from './store/actions/projectAction';

import Appbar from './Appbar';
import AppFooter from './components/Layout/AppFooter';

import Clientes from './pages/Clientes';
import Error from './pages/Error';
import DatosCliente from './pages/DatosCliente';
import Monederos from './pages/Monederos';
import WalletDef from './pages/WalletDef/index';
import Movements from './pages/Movements';
import SaldoVencido from './pages/SaldoVencido';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/layout.scss';
import './layout/App.scss';

import {
	error,
	menuItems,
	cliente,
	portada,
	perfilCliente,
	walletDef,
	monedero,
	saldovencido,
} from './configs/textos';
import {
	LIST_CUSTOMERS,
	LIST_POCKETS,
	LIST_MONEDERO,
	LIST_SALDOVENCIDO,
} from './configs/pages';
import Menu from './components/Layout/AppMenu/index';

const App = (props) => {
	const {
		global: {
			user: { pages },
		},
	} = props;

	const [initialPage, setInitialPage] = useState(1);

	useEffect(() => {
		setInitialPage(1);
	}, [pages]);

	const [visibleMenu, setVisibleMenu] = useState(false);

	const wrapperClass = classNames('layout-wrapper layout-static', {
		'layout-static-sidebar-inactive': !visibleMenu,
	});

	function getIndexComponent(page) {
		switch (page) {
			case 1:
				return Clientes;
			case 2:
				return Movements;
			default:
				return Clientes;
		}
	}

	return (
		<div className={wrapperClass}>
			<Appbar onToggleMenu={() => setVisibleMenu(!visibleMenu)} />
			<Menu
				visible={visibleMenu}
				onHide={() => setVisibleMenu(false)}
				items={menuItems}
				pages={pages}
			/>
			<div className='layout-main'>
				<Route
					path={portada.url}
					exact
					component={getIndexComponent(initialPage)}
				/>
				<Route
					{...props}
					exact
					path={cliente.url}
					component={Clientes}
					page={LIST_CUSTOMERS}
				/>
				<Route
					{...props}
					exact
					path={`${walletDef.url}/:walletDefId`}
					component={WalletDef}
					page={LIST_POCKETS}
				/>
				<Route
					{...props}
					exact
					path={`${perfilCliente.url}/:customerId`}
					component={DatosCliente}
					page={LIST_CUSTOMERS}
				/>
				<Route
					{...props}
					exact
					path={monedero.url}
					component={Monederos}
					page={LIST_MONEDERO}
				/>
				<Route
					{...props}
					exact
					path={saldovencido.url}
					component={SaldoVencido}
					page={LIST_SALDOVENCIDO}
				/>
				<Route exact path={error.url} component={Error} />
			</div>
			<AppFooter />
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(App));

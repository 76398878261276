import { LIST_CUSTOMERS, LIST_MONEDERO, LIST_SALDOVENCIDO } from './pages';

export const pageTittle = 'Smart Pockets';

export const login = {
	submit: 'Submit',
	correo: 'Correo',
	contraseña: 'Contraseña',
	entrar: 'Entrar',
	code: 'Código',
	recoverPassword: '¿Has olvidado tu contraseña?',
	recoverPasswordTitle: 'Recuperar contraseña',
	title: 'Inicio de sesión',
	newPassword: 'Nueva contraseña',
	actualPassword: 'Contraseña actual',
	changePasswordIntro: 'Por favor, introduce tu contraseña actual',
	changeNewPasswordIntro: 'Por favor, introduce tu nueva contraseña',
	repeatPassword: 'Confirma tu nueva contraseña',
	accept: 'Aceptar',
	requisites: 'Tu contraseña debe tener',
	minLength: 'Al menos 10 caracteres',
	letters: 'Mayúsculas y minúsculas',
	specialChars: 'Al menos un número y un caracter especial',
	passwordRequired: 'La contraseña es un campo requerido',
	codeRequired: 'El código es un campo requerido',
	passwordDifferent: 'Las contraseñas no coinciden',
	forgotPasswordIntro:
		'Por favor introduce tu email. Recibirás un correo con instrucciones para recuperar tu contraseña',
	email: 'Correo electrónico',
	forgotPasswordCodeIntro:
		'Por favor introduce el código que has recibido y tu nueva contraseña',
	success: 'Se ha actualizado la contraseña correctamente',
	confirmPassword: 'Confirma tu nueva contraseña',
};

export const header = {
	logout: 'Cerrar sesión',
	changePassword: 'Cambiar contraseña',
	passwordSuccess: 'Contraseña actualizada correctamente',
};

export const autobuses = {
	nombre: 'Autobuses y validadoras',
	url: '/',
};

export const portada = {
	nombre: 'index',
	url: '/',
};

export const movimientos = {
	nombre: 'Movimientos',
	url: '/movimientos',
};

export const saldovencido = {
	nombre: 'Reporte Saldos Vencidos',
	url: '/saldovencido',
};

export const pockets = {
	nombre: 'Pockets',
	url: '/pockets',
};

export const documentos = {
	nombre: 'Documentos',
	url: '/documentos',
};

export const monedero = {
	monedero: 'Monederos',
	url: '/monederos',
	table: {
		usuario: 'Usuario',
		nombre: 'Nombre y apellidos',
		nserie: 'N° serie',
		wallet_id: 'N° Monedero',
		amount: 'Saldo',
		estado: 'Estado',
		inicio: 'Fecha inicio',
		expired: 'Fecha expiración',
	},
};
export const cliente = {
	clientes: 'Clientes',
	url: '/clientes',
	table: {
		usuario: 'Usuario',
		nombre: 'Nombre y apellidos',
		email: 'Email',
		idCliente: 'Id',
		nDocument: 'Id customer unique',
		estado: 'Estado',
	},
};

export const certificado = {
	nombre: 'Documentos',
	url: '/documentos',
	table: {
		idRed: 'Id Red',
		tipoCertificado: 'Tipo de Documento',
		orden: 'Orden',
		activo: 'Activo',
	},
};

export const wallet = {
	nombre: 'Títulos virtuales',
	url: '/wallets',
};

export const movimiento = {
	nombre: 'Usuarios web',
	url: '/movimientos',
};

export const walletDef = {
	nombre: 'WalletDef',
	url: '/walletdef',
};

export const pocketDef = {
	nombre: 'Títulos virtuales',
	url: '/titulos_virtuales',
};

export const perfilCliente = {
	nombre: 'Datos cliente',
	url: '/cliente',
	datos: {
		name: 'Nombre y apellidos',
		documentId: 'Documento DNI NIF',
		document: 'Documento',
		email: 'Email',
		gender: 'Género',
		telephone: 'Teléfono',
		birthdate: 'Fecha de nacimiento',
		provincia: 'Provincia',
		country: 'País',
		job: 'Trabajo',
		registerType: 'Tipo de registro',
	},
	wallets: {
		pocket: 'Red',
		wallet: 'Título virtual',
		saldo: 'Saldo',
		inicio: 'Fecha inicio',
		expired: 'Fecha expiración',
		state: 'Estado',
		archivado: 'Archivado',
		serialNumber: 'N. serie',
	},
	certificado: {
		document: 'Documento',
		expired: 'Fecha de expiración',
		number: 'Número',
		state: 'Estado',
		add: 'Añadir documento',
		initDate: 'Fecha de inicio',
	},
	movimientos: {
		title: 'Últimos movimientos',
		wallet: 'Título',
		movement: 'Id Movimiento',
		serialNumber: 'ERPCO Transaction Id',
		type: 'Tipo',
		concept: 'Concepto',
		date: 'Fecha',
		amount: 'Importe',
		balance: 'Saldo',
		state: 'Estado',
		more: 'Más movimientos',
	},
	saldovencido: {
		title: 'Reporte Diario Saldos Vencidos',
		wallet: 'Título',
		wallet_id: 'Monedero',
		wallet_transaction_id: 'Wallet Transaction Id',
		description: 'Description',
		amount: 'Monto',
		concept: 'Concepto',
		lastCashinDate: 'Fecha Ultimo Abono',
		cancelationDate: 'Fecha Cancelacion',
		diaVencimiento: 'Días de vencimiento',
		balance: 'Saldo',
		state: 'Estado',
		more: 'Más movimientos',
	},
};

export const error = {
	nombre: 'Error',
	url: '/error',
};

export const validadora = {
	nombre: 'Validadoras',
	url: '/validadora',
};

export const bus = {
	nombre: 'Autobuses',
	url: '/autobuses',
};

export const usuarios = { nombre: 'Usuarios web', url: '/usuarios' };
export const menuItems = [
	{
		label: cliente.clientes,
		to: cliente.url,
		level: 'menu-parent',
		visible: LIST_CUSTOMERS,
	},
	{
		label: monedero.monedero,
		to: monedero.url,
		level: 'menu-parent',
		visible: LIST_MONEDERO,
	},
	{
		label: saldovencido.nombre,
		to: saldovencido.url,
		level: 'menu-parent',
		visible: LIST_SALDOVENCIDO,
	},
];

export const top = {
	salir: 'Salir',
	login: 'Login',
	es: 'es',
	en: 'en',
};

export const search = {
	label: 'Buscar',
	placeholder: 'Introduzca Id o Id customer unique',
	placeholderMonedero: 'Introduzca Id Monedero o N° serie',
};

export const dialog = {
	delete: 'Eliminar',
	cancel: 'Cancelar',
	submit: 'Aceptar',
	save: 'Guardar',
	deleteDocumentSuccess: 'Documento eliminado correctamente',
	createDocumentSuccess: 'Documento creado correctamente',
	updateDocumentSuccess: 'Documento actualizado correctamente',
	updateClientSuccess: 'Cliente actualizado correctamente',
	addClientToBlackListSucces: 'Se ha bloqueado el cliente',
	removeClientFromBlackListSucces: 'Se ha desbloqueado el cliente',
	addWalletToBlackListSucces: 'Se ha bloqueado el título',
	removeWalletFromBlackListSucces: 'Se ha desbloqueado el título',
	clientBlackList: 'Bloquear cliente',
	clientBlackListRemove: 'Cliente bloqueado',
	walletBlackList: 'Bloquear título',
	walletBlackListRemove: 'Desbloquear título',
	updateWalletSuccess: 'Título virtual actualizado correctamente',
	createValidadoraSuccess: 'Validadora creada correctamente',
	updateValidadoraSuccess: 'Validadora actualizada correctamente',
	deleteValidadoraSuccess: 'Validadora eliminada correctamente',
	createBusSuccess: 'Autobús creado correctamente',
	editBusSuccess: 'Autobús actualizado correctamente',
	deleteBusSuccess: 'Autobús eliminado correctamente',
	createUserSuccess: 'Usuario creado correctamente',
	updateUserSuccess: 'Usuario actualizado correctamente',
	createPocketSuccess: 'Pocket creado correctamente',
	updatePocketSuccess: 'Pocket actualizado correctamente',
	createWalletSuccess: 'Título virtual creado correctamente',
	deleteUserSuccess: 'Usuario eliminado correctamente',
	validateCertificateSuccess: 'Documento validado correctamente',
	denyCertificateSuccess: 'Documento denegado correctamente',
	confirmDeleteDocument: '¿Está seguro de que desea eliminar el documento?',
	headerDeleteDocument: 'Eliminar documento',
};

export const dialogCertificados = {
	certificate: 'Documento',
	selectCertificate: 'Seleccione documento',
	selectDocument: 'Seleccione documento',
	document: 'Tipo de documento',
	enterNumber: 'Introduce número',
	number: 'Número',
	initDate: 'Fecha de inicio',
	expired: 'Fecha de fin',
	notExpired: 'No caduca',
	state: 'Estado',
	certificateNumber: 'Nº documento',
	photo: 'Fotografía',
	uploadImage: 'Subir imagen',
	add: 'Añadir documento',
	edit: 'Editar documento',
	delete: 'Eliminar documento',
	files: 'Archivos',
};

export const emptyPage = 'Empty Page';

export const button = {
	edit: 'Editar',
	block: 'Bloquear',
	unblock: 'Desbloquear',
};

export const dialogClient = {
	edit: 'Editar datos personales',
	save: 'Guardar',
	name: 'Nombre',
	surname: 'Apellidos',
	birthdate: 'Fecha nacimiento',
	telephono: 'Teléfono',
	prefix: 'Prefijo',
};

export const dialogBlackList = {
	client: 'Cliente',
	wallet: 'Título',
	reason: 'Motivo',
	description: 'Descripción',
	descriptionPlaceholder: 'Escriba...',
	save: 'Guardar',
	date: 'Fecha de bloqueo',
};

export const dialogEditWallet = {
	title: 'Editar título virtual',
	save: 'Guardar',
	network: 'Red',
	wallet: 'Título virtual',
	serialNumber: 'Número de serie',
	balance: 'Saldo',
	initDate: 'Fecha inicio',
	expDate: 'Fecha expiración',
	state: 'Estado',
};

export const dialogWalletBalance = {
	title: 'Modificar saldo',
	save: 'Guardar',
	modifyBalance: 'Modificar saldo',
	accept: 'Aceptar',
	cancel: 'Cancelar',
	modify: 'Modificar',
	wallet: 'Título',
	balance: 'Saldo actual',
	finalBalance: 'Saldo final',
	reason: 'Motivo',
	amount: 'Cantidad',
	description: 'Descripción',
	descriptionPlaceholder: 'Escriba...',
	reasonPlaceholder: 'Seleccione motivo',
	amountTripPlaceholder: 'Número de viajes',
	amountMoneyPlaceholder: 'Importe (€)',
	confirmQuestion: '¿Desea confirmar esta operación?',
	confirm: 'Confirmación',
	fail: 'Operación fallida',
};

export const busesPage = {
	title: 'Autobuses',
	plate: 'Matrícula',
	code: 'Código',
	serviceClass: 'Tipo de servicio',
	machines: 'Validadoras',
	noMachines: 'Sin asignar',
	searchPlaceholder: 'Buscar por código',
	create: 'Crear autobús',
	edit: 'Editar autobús',
	number: 'Número',
	service: 'Servicio',
	serviceType: 'Tipo de servicio',
};

export const validadorasPage = {
	title: 'Validadoras',
	serialNumber: 'Nº serie',
	mac: 'Mac',
	bus: 'Autobús',
	initDate: 'Asignado desde',
	searchPlaceholder: 'Buscar por nº serie',
	create: 'Crear validadora',
	edit: 'Editar validadora',
	asignBus: 'Asignar autobús',
	code: 'Código',
	lastSync: 'Última conexión',
};

export const pocketsPage = {
	title: 'Pockets virtuales',
	name: 'Nombre',
	description: 'Descripción',
	initDate: 'Inicio vigencia',
	endDate: 'Fin vigencia',
	see: 'Ver Pocket',
	seeTitle: 'Ver Título virtual',
};

export const pocketDefInfoPage = {
	title: 'Datos Pocket',
	titleWallet: 'Títulos virtuales',
	data: 'Datos',
	validity: 'Vigencia',
	conditions: 'Condiciones económicas',
	name: 'Nombre',
	description: 'Descripción',
	init: 'Inicio',
	end: 'Fin',
	minUnits: 'Mín. unidades',
	maxUnits: 'Máx. unidades',
};

export const usersPage = {
	title: 'Usuarios Web',
	searchPlaceholder: 'Buscar por email',
	name: 'Nombre',
	create: 'Crear usuario',
	edit: 'Editar usuario',
	email: 'Email',
	network: 'Red',
	role: 'Rol',
	networks: 'Redes',
	select: 'Seleccione',
	notConfiguration: 'Aún no tiene redes configuradas',
};

export const pocketPage = {
	title: 'Usuarios web',
	searchPlaceholder: 'Buscar por email',
	titlePocket: 'List of Pockets',
	name: 'Nombre',
	minimum_units: 'Mín. unidades',
	maximum_units: 'Máx. unidades',
	description: 'Descripción',
	create: 'Crear Pocket',
	edit: 'Editar Pocket',
	email: 'Email',
	network: 'Red',
	role: 'Rol',
	networks: 'Redes',
	select: 'Seleccione',
	notConfiguration: 'Aún no tiene redes configuradas',
};

export const walletPage = {
	title: 'Usuarios web',
	titlePocket: 'List of Pockets',
	name: 'Nombre',
	requisites: 'Requisites',
	terms_and_conditions: 'terms & conditions',
	min_balance_units: 'min_balance_units',
	maximum_units: 'Máx. unidades',
	val_period_count: 'val_period_count',
	text_color: 'text_color',
	icon_color: 'icon_color',
	deposit_description: 'deposit_description',
	transfer_number: 'transfer_number',
	max_simultaneous_transfers: 'max_simultaneous',
	description: 'Descripción',
	create: 'Crear Wallet',
	edit: 'Editar Wallet',
};

export const walletDefPage = {
	title: 'Datos título virtual',
	data: 'Datos',
	validity: 'Vigencia',
	category: 'Categoría',
	conditions: 'Condiciones económicas',
	name: 'Nombre',
	description: 'Descripción',
	requisites: 'Requisitos',
	terms: 'Términos y condiciones',
	init: 'Inicio',
	end: 'Fin',
	duration: 'Duración',
	initType: 'Tipo inicialización',
	walletType: 'Tipo de bono',
	maxUnit: 'Máx unidades por usuario',
	deposit: 'Fianza',
	initialRecharge: 'Recarga inicial',
	minRecharge: 'Mín recarga',
	multiplicity: 'Multiplicidad recarga',
	maxBalance: 'Máx saldo',
	minBalance: 'Saldo mín. uso',
	certificates: 'Documentos',
};

export const filterBar = {
	export: 'Exportar CSV',
};

export const documentsPage = {
	title: 'Documentos',
	document: 'Documento',
	expDate: 'Fecha de expiración',
	actDate: 'Fecha de actualización',
	state: 'Estado',
	email: 'Email',
	pending: 'Documentos pendientes',
	pendingSingular: 'Documento pendiente',
};

export const documentDialog = {
	see: 'Ver documento',
	editTitle: 'Editar documento',
	state: 'Estado',
	category: 'Categoría',
	value: 'Número',
	actDate: 'Fecha de actualización',
	initDate: 'Fecha de inicio',
	expDate: 'Fecha de fin',
	files: 'Archivos',
	denyName: 'Motivo denegación',
	denyDescription: 'Descripción denegación',
	edit: 'Editar',
	validate: 'Validar',
	deny: 'Denegar',
	save: 'Guardar',
	renovate: 'Renovar',
	cancel: 'Cancelar',
	descriptionPlaceholder: 'Escriba ...',
	denyTitle: 'Denegar documento',
	accept: 'Aceptar',
	updateCertificateSuccess: 'Documento modificado',
	renovateCertificateSuccess: 'Documento renovado correctamente',
	renovateTitle: 'Renovar documento',
	refDoc: 'Doc. Referencia',
	notAvailable: 'No disponible',
};

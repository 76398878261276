import React from 'react';
import { connect } from 'react-redux';
import AppTopbar from '../components/Layout/AppTopbar/index';
import * as projectAction from '../store/actions/projectAction';

function Appbar({ onToggleMenu }) {
	return <AppTopbar onToggleMenu={onToggleMenu} />;
}

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);

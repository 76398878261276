import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import classNames from 'classnames';
import { Card } from 'primereact/card';
import BodyTemplate from './Dialog/BodyTemplate';
import ResponsiveDataTable from '../../../components/ResponsiveDataTable';

import { dialog, perfilCliente } from '../../../configs/textos';
import * as projectAction from '../../../store/actions/projectAction';
import { walletClienteTablaError } from '../../../configs/mensajes';
import { dateFormatUTC } from '../../../helpers/DateFormat';
import DialogEditWallet from './Dialog/DialogEditWallet';
import DialogUpdateWalletBalance from './Dialog/DialogUpdateWalletBalance';
import { getCustomerWallets } from '../../../graphql/queries';
import {
	updateWallet,
	updateWalletBalance,
	addWalletToBlackList,
	removeWalletFromBlackList,
} from '../../../graphql/mutations';
import { clientAuthCognito as client } from '../../../client';
import DialogBlackList from '../DialogBlackList';

const WalletsClienteTable = (props) => {
	const { wallet, i18n, customerId, global } = props;

	const {
		networkId,
		user: { operations },
	} = global;
	const [openDialogEditWallet, setOpenDialogEditWallet] = useState(false);
	const [openDialogBlackList, setOpenDialogBlackList] = useState(false);
	const [walletToEdit, setWalletToEdit] = useState({});
	const [openDialogUpdateWalletBalance, setOpenaDialogUpdateWalletBalance] =
		useState(false);
	const [walletToUpdateBalance, setWalletToUpdateBalance] = useState({});
	const [saldoError, setSaldoError] = useState('');
	const { t } = useTranslation();
	const toast = useRef(null);
	const [unblock, setUnblock] = useState({});

	const [updateWalletMutation, { loading: loadingUpdateWallet }] = useMutation(
		updateWallet,
		{
			client,
			onCompleted() {
				showSuccessToast(t(dialog.updateWalletSuccess));
				setOpenDialogEditWallet(false);
			},
			onError(error) {
				showErrorToast(t(error.message));
				setOpenDialogEditWallet(false);
			},
		}
	);

	const [updateWalletBalanceMutation, { loading: loadingUpdateWalletBalance }] =
		useMutation(updateWalletBalance, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.updateWalletSuccess));
				setOpenaDialogUpdateWalletBalance(false);
			},
			onError(error) {
				if (error.graphQLErrors[0].errorType !== 'SaldoError') {
					showErrorToast(t(error.message));
					setOpenaDialogUpdateWalletBalance(false);
				} else {
					setSaldoError(t(error.message));
				}
			},
		});

	const [addWalletToBlackListMutation, { loading: loadingBlackList }] =
		useMutation(addWalletToBlackList, {
			client,
			onCompleted() {
				showSuccessToast(t(dialog.addWalletToBlackListSucces));
				setOpenDialogBlackList(false);
			},
			onError(err) {
				showErrorToast(t(err.message));
				setOpenDialogBlackList(false);
			},
		});

	const blockTitle = () => {
		setOpenDialogBlackList(true);
	};

	const [
		removeWalletFromBlackListMutation,
		{ loading: loadingRemoveBlackList },
	] = useMutation(removeWalletFromBlackList, {
		client,
		onCompleted() {
			showSuccessToast(t(dialog.removeWalletFromBlackListSucces));
			setOpenDialogBlackList(false);
		},
		onError(err) {
			showErrorToast(t(err.message));
			setOpenDialogBlackList(false);
		},
	});

	function onSubmitEditWallet(updatedWallet) {
		updateWalletMutation({
			variables: { updatedWallet },
			refetchQueries: [
				{
					query: getCustomerWallets,
					variables: { customerId, networkId },
				},
			],
		});
	}

	function onSubmitUpdateWallet(updatedWalletBalance) {
		updateWalletBalanceMutation({
			variables: { updatedWalletBalance },
			refetchQueries: [
				{
					query: getCustomerWallets,
					variables: { customerId, networkId },
				},
			],
		});
	}

	function submitBlackList(walletBlackList) {
		const mutation = !walletBlackList.wallet_black_list
			? addWalletToBlackListMutation
			: removeWalletFromBlackListMutation;
		const variables = !walletBlackList.wallet_black_list
			? {
					description: walletBlackList.description,
					wallet_black_list_type_id: walletBlackList.wallet_black_list_type_id,
					wallet_id: walletBlackList.wallet_id,
					network_id: networkId,
					customer_id: customerId,
			  }
			: {
					wallet_black_list: walletBlackList.wallet_black_list,
					description_unblock: walletBlackList.description_unblock,
					wallet_black_list_unblock_type_id:
						walletBlackList.wallet_black_list_unblock_type_id,
					network_id: networkId,
					customer_id: customerId,
			  };
		mutation({
			variables: {
				variables,
			},
			refetchQueries: [
				{
					query: getCustomerWallets,
					variables: { customerId, networkId },
				},
			],
		});
	}

	function showSuccessToast(message) {
		toast?.current?.show({
			severity: 'success',
			summary: 'Éxito',
			detail: message,
			life: 3000,
		});
	}

	function showErrorToast(error) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: error,
			life: 3000,
		});
	}

	function rowClass(data) {
		return {
			'row-blocked': data.blackList,
		};
	}
	return (
		<div>
			<div className='p-page-title p-mt-6'>
				{t('Monedero')} {wallet?.[0].wallet_id}
			</div>
			<div>
				<Toast ref={toast} />
				<div className='card growable '>
					<div className='p-grid p-ml-0 '>
						<div className='datatable-responsive-demo growable customize'>
							<Card
								className={classNames('growable', {
									customCard: wallet?.length > 10,
								})}
							>
								<ResponsiveDataTable
									value={wallet}
									emptyMessage={t(walletClienteTablaError)}
									rowClassName={rowClass}
									total={wallet?.length}
									className={classNames({
										customTable: wallet?.length > 10,
									})}
								>
									<Column
										header={t(perfilCliente.wallets.wallet)}
										body={(row) => `${row.wallet_id}`}
										style={{ width: '145px' }}
									/>
									<Column
										header={t(perfilCliente.wallets.serialNumber)}
										body={(row) => `${row.wallet_serial_number}`}
										style={{ width: '145px' }}
									/>
									<Column
										field='saldo'
										header={t(perfilCliente.wallets.saldo)}
										style={{ width: '80px' }}
										body={(row) => `$ ${row.current_units_balance}`}
									/>
									<Column
										header={t(perfilCliente.wallets.inicio)}
										body={(row) =>
											row.init_date
												? dateFormatUTC(row.init_date, 'DD/MM/YYYY')
												: '-'
										}
										style={{ width: '125px' }}
									/>
									<Column
										header={t(perfilCliente.wallets.expired)}
										body={(row) =>
											row.exp_date
												? dateFormatUTC(row.exp_date, 'DD/MM/YYYY')
												: '-'
										}
										style={{ width: '125px' }}
									/>
									<Column
										style={{ width: '50px' }}
										body={(row) => (
											<div
												style={{
													display: 'flex',
													gap: '10px',
													justifyContent: 'center',
												}}
											>
												{/* Lock Icon (for BlackList/Unblock) */}
												<i
													className={`pi ${
														row.blackList ? 'pi-unlock' : 'pi-lock'
													}`}
													style={{ fontSize: '1.2rem', cursor: 'pointer' }}
													onClick={() => {
														blockTitle();
														setWalletToEdit(row);
														setUnblock(row?.blackList);
													}}
													title={
														row.blackList ? 'Unlock Wallet' : 'Lock Wallet'
													}
												/>

												{/* Dollar Icon (for Update Balance) */}
												<i
													className='pi pi-dollar'
													style={{ fontSize: '1.2rem', cursor: 'pointer' }}
													onClick={() => {
														setOpenaDialogUpdateWalletBalance(true);
														setWalletToUpdateBalance(row);
													}}
													title='Update Balance'
												/>

												{/* Edit Icon */}
												<i
													className='pi pi-pencil'
													style={{ fontSize: '1.2rem', cursor: 'pointer' }}
													title='Edit Wallet'
												/>
											</div>
										)}
									/>
									<Column
										style={{ width: '50px' }}
										body={(row) => (
											<BodyTemplate
												operations={operations}
												onEditClick={() => {
													setOpenDialogEditWallet(true);
													setWalletToEdit(row);
												}}
												onUpdateBalanceClick={() => {
													setOpenaDialogUpdateWalletBalance(true);
													setWalletToUpdateBalance(row);
												}}
												disabledUpdate={row?.unit_type_id === 3}
												onBlackListHandler={() => {
													setOpenDialogBlackList(true);
													setWalletToEdit(row);
													setUnblock(row?.blackList);
												}}
												isInBlackList={row.blackList}
											/>
										)}
									/>
								</ResponsiveDataTable>
							</Card>
						</div>
					</div>
				</div>
				<DialogEditWallet
					{...props}
					open={openDialogEditWallet}
					setOpenDialog={setOpenDialogEditWallet}
					wallet={walletToEdit}
					i18n={i18n}
					onSubmitEditWallet={onSubmitEditWallet}
					loading={loadingUpdateWallet}
				/>
				<DialogUpdateWalletBalance
					{...props}
					open={openDialogUpdateWalletBalance}
					setOpenDialog={setOpenaDialogUpdateWalletBalance}
					wallet={walletToUpdateBalance}
					i18n={i18n}
					onSubmitUpdateWallet={onSubmitUpdateWallet}
					loading={loadingUpdateWalletBalance}
					customerId={customerId}
					errors={saldoError}
					setSaldoError={setSaldoError}
				/>
				<DialogBlackList
					{...props}
					open={openDialogBlackList}
					setOpenDialog={setOpenDialogBlackList}
					title={
						!wallet?.blackList
							? t(dialog.walletBlackList)
							: t(dialog.walletBlackListRemove)
					}
					name='Tarjeta Bus (...3484)'
					unblock={unblock}
					loading={loadingBlackList || loadingRemoveBlackList}
					id={walletToEdit?.wallet_id}
					onSubmitBlackList={submitBlackList}
					i18n={i18n}
					wallet={walletToEdit}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(WalletsClienteTable));

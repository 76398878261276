import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getWalletDef } from '../../graphql/queries';
import WalletDefCard from './WalletDefCard';
import CertificatesTable from './CertificatesTable';
import { pocketDef } from '../../configs/textos';

const WalletDef = (props) => {
	const mockData = {
		getWalletDefDetails: {
			deposit: null,
			description:
				'{"en":"Billete Sencillo Description","es":"Billete Sencillo Descripción"}',
			end_date: '2021-01-01T00:00:00.000Z',
			image:
				'iVBORw0KGgoAAAANSUhEUgAAAqcAAAGpCAYAAACwB5/pAAAAAXNSR0IArs4c6QAAAARn',
			init_date: '2021-01-01T00:00:00.000Z',
			init_method: 'Fecha compra',
			initial_recharge: null,
			max_units: 1,
			maximum_units: 100,
			min_balance_units: 0,
			minimum_units: null,
			multiple_units: null,
			name: '{"en":"Billete Sencillo EN","es":"Billete Sencillo"}',
			period_type: 'Años',
			requisites:
				'{"en":"Billete Sencillo Requisites","es":"Billete Sencillo Requisitos"}',
			terms_and_conditions:
				'{"en":"Billete Sencillo Terms and conditions","es":"Billete Sencillo Términos y condiciones"}',
			unit_type: '€',
			val_period_count: 999,
			wallet_def_id: 4,
			certificates: [],
		},
	};
	const useMockData = false;
	const {
		global: {
			user: { network_id: networkId },
		},
	} = props;
	const { walletDefId } = useParams();
	const history = useHistory();
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			history.push(pocketDef.url);
		}
	}, [networkId]);

	const { data, loading } = useMockData
		? { data: mockData, loading: false }
		: useQuery(getWalletDef, {
				client,
				variables: { walletDefId },
		  });

	if (loading) return <LoadingSpinner />;

	const walletDef = data?.getWalletDefDetails || {};
	const certificates = walletDef?.certificates || [];

	return (
		<div className='p-container'>
			<WalletDefCard {...props} walletDef={walletDef} />
			<CertificatesTable {...props} certificates={certificates} />
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(mapStateToProps, mapDispatchToProps)(WalletDef);

import React from 'react';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import ResponsiveDataTable from '../../components/ResponsiveDataTable';
import Filterbar from './Filterbar';
import { movementTableError } from '../../configs/mensajes';
import { perfilCliente } from '../../configs/textos';
import * as projectAction from '../../store/actions/projectAction';
import { dateFormat } from '../../helpers/DateFormat';

const SaldoVencidoTable = (props) => {
	const {
		movements,
		i18n,
		title,
		onFilter,
		global,
		totalRecords,
		...restProps
	} = props;
	const {
		networkId,
		user: { networks },
	} = global;
	const networkName = networks?.find(
		(el) => el.network_id === networkId
	)?.short_name;
	const { t } = useTranslation();

	function onFilterHandler(data) {
		onFilter(data);
	}

	return (
		<>
			<Filterbar
				i18n={i18n}
				onFilter={onFilterHandler}
				networkName={networkName}
				networkId={networkId}
				totalRecords={totalRecords}
			/>
			<ResponsiveDataTable
				{...restProps}
				value={movements}
				emptyMessage={t(movementTableError)}
			>
				<Column
					header={t(perfilCliente.saldovencido.lastCashinDate)}
					body={(row) =>
						dateFormat(row.lastCashIn.dateLastCashIn, 'DD/MM/YYYY HH:mm:ss')
					}
				/>
				<Column
					header={t(perfilCliente.saldovencido.diaVencimiento)}
					body={(row) => row?.lastCashIn?.expiredDays}
				/>
				<Column
					header={t(perfilCliente.saldovencido.wallet_id)}
					body={(row) =>
						row?.Cust_wallet?.wallet_id
							? JSON.parse(row.Cust_wallet?.wallet_id)
							: '-'
					}
				/>
				<Column
					header={t(perfilCliente.saldovencido.amount)}
					body={(row) => (row?.amount ? `${row.amount}` : '-')}
					className='width120'
				/>
				<Column
					header={t(perfilCliente.saldovencido.cancelationDate)}
					body={(row) => dateFormat(row.date_time, 'DD/MM/YYYY HH:mm:ss')}
				/>
			</ResponsiveDataTable>
		</>
	);
};

const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(SaldoVencidoTable));

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { clientAuthCognito as client } from '../../../client';
import { SaldoVencido } from '../../../graphql/queries';
import { setMovementsExpired } from '../../../store/actions/commonAction';

const useGetMovements = ({
	refetchQuery,
	setRefetchQuery,
	ListWalletBalanceExpiredAndRemovedInput,
}) => {
	const selectedDate = useSelector((state) => state.dropdownValue); // Get from Redux
	const dispatch = useDispatch();

	// const adjustedStartDate = selectedDate
	// 	? new Date(
	// 			new Date(selectedDate).setDate(new Date(selectedDate).getDate() - 1)
	// 	  ).toISOString()
	// 	: '2025-02-08T00:00:00Z';

	const queryParams = {
		client,
		variables: {
			input: ListWalletBalanceExpiredAndRemovedInput || {
				startDate: selectedDate,
				endDate: selectedDate,
			}, // Ensure it never sends null
		},
	};

	const { data, loading, error, refetch } = useQuery(SaldoVencido, queryParams);
	useEffect(() => {
		if (refetchQuery) {
			refetch()
				.then(() => {})
				.catch((err) => console.error('Refetch error:', err))
				.finally(() => {
					setRefetchQuery(false);
				});
		}
	}, [refetchQuery, refetch, setRefetchQuery]);
	useEffect(() => {
		if (data) {
			dispatch(setMovementsExpired(data));
		}
	}, [data]);

	return { data, loading, error };
};

export default useGetMovements;
